import * as React from 'react'
import { Heading } from '../components/Heading'
import * as styles from './Home3Engineering.module.scss'
import wallImg from '../images/wall.jpg'

export function Home3Engineering() {
  return (
    <div className={styles.engineering}>
      <div className="container">
        <div className="section-title">
          <div className="row">
            <div className="col-lg-4 d-none d-lg-block">
              <img className={styles.engineeringImg} src={wallImg} />
            </div>
            <div className="col-lg-8 py-3">
              <Heading>Engineering</Heading>
              <p>
                The PMC multi-discipline engineering team is responsible for
                revision of engineering documents at various stages of the
                project phases including:
              </p>
              <ol className={styles.list}>
                <li>
                  Review of and approve conceptual and basic design reports.
                </li>
                <li>Review and approve of detailed design documents</li>
                <li>
                  Review of and approve project schedule and engineering
                  requirements.
                </li>
                <li>
                  Review of engineering progress and status for engineering
                  deliverables.
                </li>
                <li>
                  Review of design Safety, Integrity, and Reliability as well as
                  participating in HAZOP exercise
                </li>
                <li>
                  Manage Documentation Control, handover Cost Reporting and
                  Change Order administration.
                </li>
                <li>Review of EPCC Contractor claims/payments.</li>
              </ol>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
