import * as React from 'react'
import { ParallaxBanner } from 'react-scroll-parallax'

import { Heading } from '../components/Heading'
import structureImg from '../images/structures.jpg'

export function Home4Procurement() {
  return (
    <div className="pt-4">
      <div className="container">
        <div className="section-title">
          <Heading>Procurement</Heading>
          <p>
            During procurement stage PMC responsibility include the following:
          </p>
          <p>
            Monitoring of EPCC Contractor’s procurement activities including
            approval of Material Take-Off (MTO), review of additional Vendor
            List, review of Inquiry or Material Requisition (MR) documents,
            review of Technical Evaluation Reports (TER), Purchase Orders (PO),
            Expediting Reports, and Vendor Documentation.
          </p>
          <p>
            Review and approve Tax exemption documentation and inland
            transportation. Inland transportation ensures procurement and
            delivery of correct and quality materials; deployment of the 3rd
            Party Inspection, witness of Factory Acceptance Test (FAT) and Site
            Acceptance Test (SAT) to ensure EPCC Contractors are in compliance
            with Owner’s Approved Vendors List (AVL).
          </p>
          <p>
            Review EPCC Contractor’s Purchase requisition Documents to ensure
            compliance with Design Specifications, Codes & Standards, Spare
            Parts, Data Sheets and verify the Proposed Delivery matching with
            procurement schedule/Required on Site (ROS).
          </p>
          <p>
            Ensure EPCC Contractor’s expediting role is effectively functioning
            and manufacturing progress and shipment are closely monitored and
            tracked with updated estimate time of arrival (ETA).
          </p>
          <p>
            Monitor the submission of EPCC Contractor’s Procurement Reports and
            documents such as 3rd Party Inspection Reports, Purchased Order
            Report, Material Delivery Status Report, and Material/Stock Status
            Report
          </p>
        </div>
      </div>
      <ParallaxBanner
        layers={[
          {
            image: structureImg,
            amount: 0.4
          }
        ]}
        style={{
          width: '100%',
          minHeight: '35vh',
          margin: '2rem 0 0'
        }}
      />
    </div>
  )
}
