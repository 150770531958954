import * as React from 'react'
import { ParallaxBanner } from 'react-scroll-parallax'

import silhouetteImg from '../images/silhouette.jpg'
import { Heading } from '../components/Heading'

export function Home6Commissioning() {
  return (
    <div>
      <div className="container pt-5">
        <div className="section-title">
          <Heading>Commissioning</Heading>
          <p>
            PMC is responsible for overall coordination and management of
            pre-commissioning, commissioning and startup activities which
            include:
          </p>
          <p>
            Review and approve pre-commissioning and commissioning procedures.
          </p>
          <p>
            Coordinate with contractors and vendors’ representatives during
            commissioning activities of critical equipment and systems at site.
          </p>
          <p>Witness start-up of the systems and performance test run.</p>
          <p>
            Coordinating between the client, Contractor and Vendors personnel
            for critical assistance to contractors.
          </p>
          <p>
            Plan and supervise training activities for owner personnel by
            EPCC/Vendor Contractor during Commissioning activities.
          </p>
          <p>
            Recommend for issuance of Provisional Acceptance monitor and
            supervise rectification of defects, if any prior to commencement of
            warranty period.
          </p>
        </div>
      </div>
      <ParallaxBanner
        layers={[
          {
            image: silhouetteImg,
            amount: 0.4
          }
        ]}
        style={{
          width: '100%',
          minHeight: '35vh',
          margin: '2rem 0 0'
        }}
      />
    </div>
  )
}
