import * as React from 'react'
import { Heading } from '../components/Heading'
import * as styles from './Home2ProjectManagement.module.scss'
import oilTopImg from '../images/oilTop.jpg'

export function Home2ProjectManagement() {
  return (
    <div
      className={styles.projectManagementContainer}
      style={{
        backgroundImage: `url(${oilTopImg})`
      }}
    >
      <div className="container position-relative text-justify">
        <div className="section-title">
          <Heading grey>Project Management</Heading>
          <p>
            SPECS PMC team consists of experienced professional experts of
            different engineering disciplines such as Electrical, Mechanical,
            Civil and Process as well as specialists of other branches of
            project management and control that include procurement, contract,
            and document control.
          </p>
          <p>
            The team comprises of professionals of several nationalities
            including South Sudanese, Malaysian, Indian, Filipinos, Chinese,
            etc. providing utmost PMC professional services in-line with the
            engineering design, supervision, monitoring, inspection and
            commissioning activities for our clients’ in the Republic of South
            Sudan. Our present clients include: DAR Petroleum Operating Company
            (DPOC), Great Pioneer Operating Company (GPOC), Sudd Petroleum
            Operating Company (SPOC). Due to the quality of work that SPECS has
            so far delivered, it is hoped that more potential clients will be
            attracted in times to come.
          </p>
          <p>
            PMC team isa very dedicated team that works around the clock
            performing highly technical services which include but not limited
            to:
          </p>
          <p>
            Technical and commercial review of the conceptual, Front End
            Engineering, Basic and Detailed Engineering Design
          </p>
          <p>
            Managing the pre-award activities, such as & approval of ITB,
            Assessment, Approval and Award Recommendation of EPCC Contractor,
            Finalization of Contract document and Project Kick-off Meeting
          </p>
          <p>
            Monitoring and verifying all EPCC Contractor’s work activities and
            construction schedules during post award period.
          </p>
          <p>
            Managing and administering all Contract Packages which include
            compliance verification towards Provision of Contract, Review of
            Change Order/Work Order Requests and Resolution of Contractual
            issues/claims.
          </p>
          <p>
            To monitoring & managing post Project Activities, such as Punch-list
            Clearance, Final Documentation and Start of Warranty Period after
            Provisional Acceptance date
          </p>
        </div>
      </div>
    </div>
  )
}
