import * as React from 'react'
import * as classNames from 'classnames'
import craneImage from '../images/crane.jpg'
import * as styles from './About.module.scss'

import { Heading } from '../components/Heading'
import { Navigation } from '../Home/Navigation'

export function About() {
  return (
    <div className={styles.about}>
      <Navigation />
      <div className="container">
        <div className="row">
          <div className="col-xs-12 col-lg-6 d-none d-lg-block">
            <img
              src={craneImage}
              className={classNames(styles.aboutImg, 'img-responsive')}
              alt="Crane"
            />
          </div>
          <div className="col-xs-12 col-lg-6">
            <div>
              <Heading>About Us</Heading>
              <p>Deep expertise in Project Management Consultancy</p>
              <p>
                Projexive's Project Management Consultancy business unit
                supports contract execution throughout the project life cycle
                and provides a single point of customer contact. Teams are
                staffed by experts with extensive experience managing large oil
                and gas contracts and helping clients control the cost of their
                investments.
              </p>
              <p>The EPC contractor with best-in-class project management</p>
              <p>
                We help clients achieve their investment objectives and deliver
                projects by consulting at every project phase. From technology
                and licensor selection to management of multinational consortia
                and delivery of turnkey projects, our experts have carried out
                some of the world’s most challenging EPC projects.
              </p>
              <p className="mb-1">Consultancy for all types of projects</p>
              <ul className={styles.projectTypeList}>
                <li>Project management</li>
                <li>Resourcing strategy</li>
                <li>Project controls</li>
                <li>Engineering and procurement</li>
                <li>Construction</li>
                <li>Commissioning</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
