import * as React from 'react'
import { Heading } from '../components/Heading'

export function Home7HSEPolicy() {
  return (
    <div>
      <div className="container py-5">
        <div className="section-title">
          <Heading>HSE POLICY</Heading>
          <p>
            Our primary goal is to create and maintain safe and healthy working
            conditions for increased productivity by eliminating potential
            hazards through the application of hierarchy of hazard control
            system such as engineering and administrative controls as well as
            strict adherent to safe standard operating procedures and company
            regulatory policies.
          </p>
          <p>
            We are committed to lead a business that extraordinarily focuses on
            continuous improvement in Occupational Health, Safety &
            Environmental (OHSE) management systems and excellent safety
            performance in order to prevent work place injuries, fatalities, and
            property damage and loss production time at our work environment.
            These are achieved through;
          </p>
          <p>
            Administering continual personnel training and hazard awareness
            (WHMIS) at workplaces
          </p>
          <p>
            Application of inherent safety principles (Engineering control) such
            as designing and installation equipment that eliminate and remove
            hazards
          </p>
          <p>
            Development of standard operating procedures (SOPs), work permit and
            safe work practices
          </p>
          <p>Strict implementation of company safe practices</p>
          <p>
            Constant evaluation of effectiveness of the safety management
            systems through regular audits and management reviews.
          </p>
        </div>
      </div>
    </div>
  )
}
