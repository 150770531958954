import * as React from 'react'
import { graphql, PageProps } from 'gatsby'

import { Layout } from '../components/Layout'
import { SEO } from '../components/Seo'
import { About } from '../Home/About'
import { Home1Strategy } from '../Home/Home1Strategy'
import { Home2ProjectManagement } from '../Home/Home2ProjectManagement'
import { Home3Engineering } from '../Home/Home3Engineering'
import { Home4Procurement } from '../Home/Home4Procurement'
import { Home5Construction } from '../Home/Home5Construction'
import { Home6Commissioning } from '../Home/Home6Commissioning'
import { Home7HSEPolicy } from '../Home/Home7HSEPolicy'
import { Contact } from '../Home/Contact'

type DataProps = {
  site: {
    buildTime: string
  }
}

const HomePage: React.FC<PageProps<DataProps>> = ({ data, path }) => (
  <Layout>
    <SEO title="Projexive FZ LLC." description="Your successful partners." />
    <About />
    <Home1Strategy />
    <Home2ProjectManagement />
    <Home3Engineering />
    <Home4Procurement />
    <Home5Construction />
    <Home6Commissioning />
    <Home7HSEPolicy />
    <Contact />
  </Layout>
)

export default HomePage

export const query = graphql`
  {
    site {
      buildTime(formatString: "YYYY-MM-DD hh:mm a z")
    }
  }
`
