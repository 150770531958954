import * as React from 'react'
import * as classNames from 'classnames'
import * as styles from './Home1Strategy.module.scss'
import { Heading } from '../components/Heading'
import oilIndustry from '../images/oilIndustry.jpg'

export function Home1Strategy() {
  return (
    <div
      className={classNames(styles.strategy, 'text-center')}
      style={{
        backgroundImage: `url(${oilIndustry})`
      }}
    >
      <div className={classNames(styles.strategyContent, 'container')}>
        <div className="section-title">
          <Heading white center>
            Strategy
          </Heading>
          <p className="text-justify">
            Our strategy to excel in the oil and Gas Industry is to apply the
            latest technology couple with expertise from our experienced
            engineers in order to deliver exceptional quality services,
            efficiently, safely and less costly to our esteemed clients. We
            believe that by delivering quality services safely within the budget
            and ahead of schedule, we are assured of long-term relationship with
            our clients and more importantly we are able to attract new clients.
            This strategy has enabled us to be a dominant force in oil and gas
            industry in South Sudan and hopefully it will enable us to dominate
            the oil and gas industry in Africa and in the global.
          </p>
        </div>
      </div>
    </div>
  )
}
