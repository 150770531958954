import * as React from 'react'
import { useState } from 'react'
import * as classNames from 'classnames'
import { Heading } from '../components/Heading'
import * as styles from './Contact.module.scss'

function encode(data) {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
    .join('&')
}

export function Contact() {
  const [formData, setFormData] = useState({})

  function handleOnChange(e) {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    })
  }

  function handleSubmit(e) {
    e.preventDefault()
    const form = e.target
    fetch('/', {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: encode({
        'form-name': form.getAttribute('name'),
        ...formData
      })
    })
      .then(() => {
        form.reset()
      })
      .catch(error => alert(error))
  }

  return (
    <div className={styles.contact}>
      <div>
        <div className="container">
          <div className="col-md-8">
            <div className="row">
              <div className="section-title">
                <Heading>Contact Us</Heading>
                <p>
                  Please fill out the form below to send us an email and we will
                  get back to you as soon as possible.
                </p>
                <form
                  className="form row"
                  name="enquiry"
                  method="post"
                  data-netlify="true"
                  data-netlify-honeypot="bot-field"
                  onSubmit={handleSubmit}
                >
                  {/* The `form-name` hidden field is required to support form submissions without JavaScript */}
                  <input type="hidden" name="form-name" value="contact" />
                  <div hidden>
                    <label>
                      Don’t fill this out:{' '}
                      <input name="bot-field" onChange={handleOnChange} />
                    </label>
                  </div>

                  <div className="col-md-6">
                    <div className="form-group">
                      <input
                        name="name"
                        placeholder="Name"
                        className={classNames(
                          styles.formControl,
                          'form-control'
                        )}
                        type="text"
                        onChange={handleOnChange}
                        onBlur={handleOnChange}
                        required
                      />
                      <input
                        name="email"
                        placeholder="Email"
                        className={classNames(
                          styles.formControl,
                          'form-control'
                        )}
                        type="email"
                        onChange={handleOnChange}
                        onBlur={handleOnChange}
                        required
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <textarea
                      placeholder="How can we help?"
                      className={classNames(styles.formControl, 'form-control')}
                      name="description"
                      rows={4}
                      style={{
                        minHeight: 'calc(135px - 1.5rem)'
                      }}
                      onChange={handleOnChange}
                      onBlur={handleOnChange}
                      required
                    />
                    <div className="text-right">
                      <button
                        className={classNames(styles.btn, 'btn')}
                        style={{
                          backgroundColor: 'white'
                        }}
                      >
                        Submit
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
