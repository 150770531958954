import * as React from 'react'
import * as styles from './Home5Construction.module.scss'
import { Heading } from '../components/Heading'
import architecture from '../images/architecture.jpg'
import { ParallaxBanner } from 'react-scroll-parallax'

export function Home5Construction() {
  return (
    <ParallaxBanner
      className={styles.construction}
      layers={[
        {
          image: architecture,
          amount: -0.4
        }
      ]}
      style={{
        height: 'auto'
      }}
    >
      <div className={styles.overlay} />
      <div>
        <div className="container position-relative">
          <div className="section-title">
            <Heading white>Construction</Heading>
            <p>
              PMC is responsible to ensure EPCC Contractor take full commitment
              and assume responsibilities in overall site coordination,
              construction supervision and management including the following:
            </p>
            <p>
              Review and approve contractor construction plans, schedules and
              procedures, cost control, Monitoring of Change Order (CO)
              administration and construction safety requirements.
            </p>
            <p>Review construction progress and status</p>
            <p>
              Carry out inspection of construction activities and material/
              equipment to be installed.
            </p>
            <p>
              Review and verify mechanical completion requirements, As-Built
              drawings verification and submission of Final Documentation
              scheduling
            </p>
            <p>
              Work hand in hand with production department especially in areas
              where tie-in to the existing facilities is proposed.
            </p>
            <p>
              Review and recommend for client’s approval of any field Change
              Proposed by EPCC Contractor.
            </p>
          </div>
        </div>
      </div>
    </ParallaxBanner>
  )
}
