import * as React from 'react'
import { FC } from 'react'
import * as classNames from 'classnames'
import * as styles from './Heading.module.scss'

export const Heading: FC<any> = ({
  children,
  className,
  white,
  lightBlue,
  center,
  grey,
  ...props
}) => {
  return (
    <h2
      className={classNames(className, styles.heading, {
        [styles.white]: white,
        [styles.center]: center,
        [styles.greyUnderline]: grey
      })}
      {...props}
    >
      {children}
    </h2>
  )
}
